<template>
  <b-modal
    id="property-select"
    :visible="showPropertySelectModal"
    :title="$attrs.title"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:show-property-select-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ $attrs.title }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div />

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>
    <!-- Modal: Body -->
    <div>
      <div>
        <b-card
          class="p-0 m-0"
          style="margin-top: 0.8rem !important;"
        >
          <b-card-title style="margin-bottom: 0.4rem !important;">
            <div>
              <small
                v-if="propertyData.project !== undefined"
              >{{ `${$t('Select')} ${$t('Property')}` }}</small>
              <b-alert
                v-if="propertyData.project !== undefined"
                show
                variant="success"
                style="margin-top: 0.2rem;"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="CheckCircleIcon"
                  />
                  <span class="ml-25">{{ propertyData.project }}</span>
                </div>
              </b-alert>
            </div>
            <div>
              <b-row>
                <b-col cols="6">
                  <small
                    v-if="propertyData.code !== undefined"
                  >{{ `${$t('Property Code')}` }}</small>
                  <b-alert
                    v-if="propertyData.code !== undefined"
                    show
                    variant="info"
                    style="margin-top: 0.2rem;"
                  >
                    <div class="alert-body">
                      <feather-icon
                        class="mr-25"
                        icon="InfoIcon"
                      />
                      <span class="ml-25">{{ propertyData.code }}</span>
                    </div>
                  </b-alert>
                </b-col>
                <b-col cols="6">
                  <small
                    v-if="propertyData.license !== undefined"
                  >{{ `${$t('License Number')}` }}</small>
                  <b-alert
                    v-if="propertyData.license !== undefined"
                    show
                    variant="info"
                    style="margin-top: 0.2rem;"
                  >
                    <div class="alert-body">
                      <feather-icon
                        class="mr-25"
                        icon="InfoIcon"
                      />
                      <span class="ml-25">{{ propertyData.license }}</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
            </div>
          </b-card-title>

          <div style="margin: 0rem 2rem 2rem 2rem;">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                sm="12"
                md="6"
              >
                <label>{{ $t('Entries') }}</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOption"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-50 mr-1"
                />
              </b-col>

              <b-col
                cols="12"
                sm="12"
                md="6"
              >
                <div
                  class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0"
                >
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="`${$t('Search')}...`"
                  />
                </div>
              </b-col>
            </b-row>

          </div>
          <b-table
            ref="refTable"
            :items="propertyList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            hover
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
            selectable
            select-mode="multi"
          >
            <!-- Column: Id -->
            <template #cell(id)="data">
              <!-- Id -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('ID') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        variant="light-info"
                        class="text-capitalize"
                      >
                        {{ data.item.id }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Project,Desc,Remark/Address,City -->
            <template #cell(property)="data">
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Project') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <span v-html="data.item.project" />
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Description') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <span v-html="data.item.description" />
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Remark') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <span v-html="data.item.remark" />
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Address') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <span>{{ data.item.address }}</span>
                    </span>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <b-row>
                      <b-col cols="4">
                        <span style="margin-right: 0.6rem;">
                          {{ $t('City') }} »
                        </span>
                        <span style="font-size: 14px;">
                          {{ data.item.address }}
                        </span>
                      </b-col>
                      <b-col cols="4">
                        <span style="margin-right: 0.6rem;">
                          {{ $t('District') }} »
                        </span>
                        <span style="font-size: 14px;">
                          {{ data.item.district }}
                        </span>
                      </b-col>
                      <b-col cols="4">
                        <span style="margin-right: 0.6rem;">
                          {{ $t('Province') }} »
                        </span>
                        <span style="font-size: 14px;">
                          {{ data.item.province }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <b-row>
                      <b-col cols="6">
                        <span style="margin-right: 0.6rem;">
                          {{ $t('Guarantee Price') }} »
                        </span>
                        <span style="font-size: 14px;">
                          {{ data.item.guarantee_price }}
                        </span>
                      </b-col>
                      <b-col cols="6">
                        <span style="margin-right: 0.6rem;">
                          {{ $t('Consignment Price') }} »
                        </span>
                        <span style="font-size: 14px;">
                          {{ data.item.consignment_price }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <b-row>
                      <b-col cols="6">
                        <span style="margin-right: 0.6rem;">
                          {{ $t('Size') }} »
                        </span>
                        <span style="font-size: 14px;">
                          {{ data.item.size }}
                        </span>
                      </b-col>
                      <b-col cols="6">
                        <span style="margin-right: 0.6rem;">
                          {{ $t('Usable Area') }} »
                        </span>
                        <span style="font-size: 14px;">
                          {{ data.item.usable_area }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <!-- State -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('State') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        :variant="`light-${statePropertyVariant(data.item.state)}`"
                        class="text-capitalize"
                      >
                        {{ $t(statePropertyToText(data.item.state)) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div
                style="margin-top: 0.3rem;"
              >
                <b-button
                  v-if="checkSelect(data.item)"
                  size="sm"
                  variant="primary"
                  style="margin-left: 0rem;"
                  @click.stop="onSelect(data.item)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Select') }}</span>
                </b-button>

                <b-button
                  v-else
                  size="sm"
                  variant="outline-success"
                  style="margin-left: 0rem;"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Selected') }}</span>
                </b-button>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">
                  {{ $t('Showing {from} to {to} of {of} entries',
                        {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                  }}
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItem"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BPagination,
  BAlert,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormInput,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeProperty from './storeProperty'
import constProperty from './constProperty'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BPagination,
    BAlert,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormInput,

    vSelect,
  },
  model: {
    prop: 'showPropertySelectModal',
    event: 'update:show-property-select-modal',
  },
  props: {
    showPropertySelectModal: {
      type: Boolean,
      required: true,
    },
    propertyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      busy: false,
      tableColumns: [],
      perPage: 10,
      totalItem: 0,
      currentPage: 1,
      perPageOption: [2, 5, 10, 25, 50, 100],
      sortBy: 'project',
      isSortDirDesc: false,
      locale: 'th',
      searchQuery: '',
      shallShowPropertySelectFormModal: false,
      propertyList: [],
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refTable ? this.$refs.refTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItem,
      }
    },
  },
  watch: {
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    locale() {
      this.refetchData()
    },
    searchQuery() {
      this.refetchData()
    },
    propertyData() {
      this.getListPropertySelect()
    },
  },
  mounted() {
    const msgList = {
      id: `${this.$i18n.t('ID')}`,
      property: `${this.$i18n.t('Property')}`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      { key: 'id', sortable: true, label: msgList.id },
      { key: 'property', sortable: false, label: msgList.property },
      { key: 'actions', label: msgList.actions },
    ]
  },
  methods: {
    getListPropertySelect() {
      const params = {
        propertyId: this.propertyData.id,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
        searchQuery: this.searchQuery,
      }
      store
        .dispatch('store-property/getListPropertySelect', params)
        .then(response => {
          const { list, total } = response.data
          this.propertyList = list
          this.totalItem = total
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    onDiscard() {
      this.$emit('discard-property-select')
      this.$emit('update:show-property-select-modal', false)
    },
    refetchData() {
      this.getListPropertySelect()
    },
    onSelect(item) {
      this.busy = true
      this.$emit('update-property-select', { select: item, property: this.propertyData })
      this.$emit('update:show-property-select-modal', false)
      this.busy = false
    },
    checkSelect(item) {
      if (item.id === this.propertyData.id) {
        return false
      }
      return true
    },
  },
  setup() {
    if (!store.hasModule('store-property')) store.registerModule('store-property', storeProperty)
    onUnmounted(() => {
      if (store.hasModule('store-property')) store.unregisterModule('store-property')
    })

    const {
      statusVariant,
      statusToText,
      textFirstUpper,
      deletedToText,
      deletedVariant,
      suggestToText,
      suggestVariant,
      stateToText,
      stateVariant,
      statePropertyToText,
      statePropertyVariant,
    } = constProperty()

    return {
      statusVariant,
      statusToText,
      textFirstUpper,
      deletedToText,
      deletedVariant,
      suggestToText,
      suggestVariant,
      stateToText,
      stateVariant,
      statePropertyToText,
      statePropertyVariant,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.property-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
