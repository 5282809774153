import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
