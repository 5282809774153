<template>
  <div
    v-if="$can('read', 'Auction')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <div style="margin: 2rem 2rem 2rem 2rem;">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <label>{{ $t('Entries') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOption"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>

            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <div
                class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0"
              >
                <b-button
                  v-if="$can('create', 'Auction')"
                  variant="primary"
                  class="w-50"
                  @click="addAuction"
                >
                  {{ `${$t('Add')} ${$t('Auction')}` }}
                </b-button>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 ml-1"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refTable"
          :items="auctionData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          selectable
          select-mode="multi"
        >
          <!-- Column: Id/IsSuggest -->
          <template #cell(id)="data">
            <!-- Id -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('ID') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      variant="light-info"
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- IsSuggest -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="`light-${suggestVariant(data.item.is_suggest)}`"
                    >
                      {{ $t(suggestToText(data.item.is_suggest)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Auction -->
          <template #cell(auction)="data">
            <!-- Column: AuctionDateTime -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Create') }} »
                  </span>
                  <span style="margin-right: 0.4rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.auction_date_show }}</span>
                  <span style="margin-left: 0.6rem; margin-right: 0.4rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.auction_time_show }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Column: AuctionBy -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('By') }} »
                  </span>
                  <span style="font-size: 16px;">
                    <b-badge
                      pill
                      variant="light-secondary"
                    >
                      {{ data.item.auction_by }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Column: StartDateTime/StopDateTime -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Start') }} »
                  </span>
                  <span style="margin-right: 0.4rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.start_date_show }}</span>
                  <span style="margin-left: 0.6rem; margin-right: 0.4rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.start_time_show }}</span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Stop') }} »
                  </span>
                  <span style="margin-right: 0.4rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.stop_date_show }}</span>
                  <span style="margin-left: 0.6rem; margin-right: 0.4rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ data.item.stop_time_show }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Column: Project/Address/Code/License -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Project') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="data.item.project" />
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Address') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span>{{ data.item.address }}</span>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Property Code') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      variant="light-success"
                      class="text-capitalize"
                    >
                      {{ data.item.code }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('License Number') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      variant="light-warning"
                      class="text-capitalize"
                    >
                      {{ data.item.license }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <!-- Status -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Status') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="`light-${statusVariant(data.item.status)}`"
                      class="text-capitalize"
                    >
                      {{ $t(statusToText(data.item.status)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- State -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="`light-${stateVariant(data.item.state)}`"
                    >
                      {{ $t(stateToText(data.item.state)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- IsDeleted -->
            <div
              v-if="data.item.is_deleted === true"
              style="margin-top: 0.3rem;"
            >
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="`light-${deletedVariant(data.item.is_deleted)}`"
                    >
                      {{ $t(deletedToText(data.item.is_deleted)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div
              class="text-nowrap"
              style="margin-top: 15px; text-align: left;"
            >
              <feather-icon
                v-if="$can('write', 'Auction')"
                :id="`auction-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="editAuctionForm(data)"
              />
              <b-tooltip
                v-if="$can('write', 'Auction')"
                :title="`${$t('Edit')} ${$t('Auction')}`"
                :target="`auction-row-${data.item.id}-edit-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('read', 'Auction')"
                :id="`auction-row-${data.item.id}-view-icon`"
                icon="EyeIcon"
                size="16"
                class="cursor-pointer mr-1"
                @click="viewAuctionForm(data)"
              />
              <b-tooltip
                v-if="$can('read', 'Auction')"
                :title="`${$t('View')} ${$t('Auction')}`"
                :target="`auction-row-${data.item.id}-view-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('delete', 'Auction')"
                :id="`auction-row-${data.item.id}-delete-icon`"
                icon="TrashIcon"
                variant="light-success"
                size="16"
                class="cursor-pointer mr-1"
                @click="deleteAuction(data)"
              />
              <b-tooltip
                v-if="$can('delete', 'Auction')"
                :title="`${$t('Delete')} ${$t('Auction')}`"
                :target="`auction-row-${data.item.id}-delete-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="right"
              />

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItem"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

    </b-overlay>

    <auction-form
      v-model="shallShowAuctionFormModal"
      :auction-item="auctionItem"
      :type-auction-form="typeAuctionForm"
      @auction-form-update="updateAuctionForm"
      @discard-auction-form="discardAuctionForm"
    />
  </div>
</template>

<script>
import {
  BOverlay,
  BButton,
  BCard,
  BTable,
  BRow,
  BCol,
  BPagination,
  BTooltip,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormInput,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuction from './storeAuction'
import constAuction from './constAuction'
import AuctionForm from './AuctionForm.vue'

export default {
  components: {
    BOverlay,
    BButton,
    BCard,
    BTable,
    BRow,
    BCol,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormInput,

    vSelect,
    AuctionForm,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      showOverlay: false,
      busy: false,
      tableColumns: [],
      perPage: 10,
      totalItem: 0,
      currentPage: 1,
      perPageOption: [2, 5, 10, 25, 50, 100],
      sortBy: 'start_datetime',
      isSortDirDesc: true,
      locale: 'th',
      searchQuery: '',
      auctionData: [],
      shallShowAuctionFormModal: false,
      typeAuctionForm: '',
      auctionItem: {},
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refTable ? this.$refs.refTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItem,
      }
    },
  },
  watch: {
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    locale() {
      this.refetchData()
    },
    searchQuery() {
      this.refetchData()
    },
  },
  mounted() {
    const msgList = {
      id: `${this.$i18n.t('ID')} » ${this.$i18n.t('Set Suggest')}`,
      auction: `${this.$i18n.t('Auction')} » ${this.$i18n.t('Date/Time')}`,
      status: `${this.$i18n.t('Status')} » ${this.$i18n.t('Set Deleted')}`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      { key: 'id', sortable: true, label: msgList.id },
      { key: 'auction', sortable: false, label: msgList.auction },
      { key: 'status', sortable: false, label: msgList.status },
      { key: 'actions', label: msgList.actions },
    ]
    this.getListAuction()
  },
  methods: {
    getListAuction() {
      const params = {
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
        searchQuery: this.searchQuery,
      }
      store
        .dispatch('store-auction/getListAuction', params)
        .then(response => {
          const { list, total } = response.data
          this.auctionData = list
          this.totalItem = total
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    addAuction() {
      this.showOverlay = true
      store
        .dispatch('store-auction/getDefaultAuction', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.auctionItem = response.data
          this.typeAuctionForm = 'Add'
          this.shallShowAuctionFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editAuctionForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-auction/getAuction', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.auctionItem = response.data
          this.typeAuctionForm = 'Edit'
          this.shallShowAuctionFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewAuctionForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-auction/getAuction', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.auctionItem = response.data
          this.typeAuctionForm = 'View'
          this.shallShowAuctionFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteAuction(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-auction/deleteAuction', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    refetchData() {
      this.getListAuction()
    },
    updateAuctionForm() {
      this.refetchData()
    },
    discardAuctionForm() {
      this.refetchData()
    },
  },
  setup() {
    if (!store.hasModule('store-auction')) store.registerModule('store-auction', storeAuction)
    onUnmounted(() => {
      if (store.hasModule('store-auction')) store.unregisterModule('store-auction')
    })

    const {
      statusVariant,
      statusToText,
      textFirstUpper,
      deletedToText,
      deletedVariant,
      suggestToText,
      suggestVariant,
      stateToText,
      stateVariant,
    } = constAuction()

    return {
      statusVariant,
      statusToText,
      textFirstUpper,
      deletedToText,
      deletedVariant,
      suggestToText,
      suggestVariant,
      stateToText,
      stateVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.auction-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.action-icon {
  :hover {
    color: #429dc8 !important;
  }
}
</style>
