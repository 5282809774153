export default function constAuction() {
  const statusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'danger'
    return 'danger'
  }

  const statusToText = status => {
    if (status === 'active') return 'Active'
    if (status === 'inactive') return 'Inactive'
    return ''
  }
  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  const deletedToText = deleted => {
    if (deleted === false) return 'No'
    if (deleted === true) return 'Deleted'
    return ''
  }
  const deletedVariant = deleted => {
    if (deleted === false) return 'secondary'
    if (deleted === true) return 'danger'
    return 'danger'
  }

  const suggestToText = deleted => {
    if (deleted === false) return 'No'
    if (deleted === true) return 'Suggest'
    return ''
  }
  const suggestVariant = deleted => {
    if (deleted === false) return 'secondary'
    if (deleted === true) return 'info'
    return 'danger'
  }

  const stateToText = state => {
    if (state === 'auction') return 'Auction'
    if (state === 'closed') return 'Closed'
    return ''
  }
  const stateVariant = state => {
    if (state === 'auction') return 'success'
    if (state === 'closed') return 'danger'
    return 'secondary'
  }

  return {
    statusVariant,
    statusToText,
    textFirstUpper,
    deletedToText,
    deletedVariant,
    suggestToText,
    suggestVariant,
    stateToText,
    stateVariant,
  }
}
