import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultProperty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_property', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListProperty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_property', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProperty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_property', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProperty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_property', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProperty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_property', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProperty(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_property', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    propertyOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/property_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListPropertySelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_property_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListPropertyImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_property_image', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultPropertyImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_property_image', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPropertyImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_property_image', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePropertyImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_property_image', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImagePropertyImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_property_image/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListPropertyLocation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_property_location', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultPropertyLocation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_property_location', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPropertyLocation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_property_location', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPropertyLocation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_property_location', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPropertyLocation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_property_location', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePropertyLocation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_property_location', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListPropertyAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_property_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultPropertyAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_property_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPropertyAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_property_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPropertyAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_property_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPropertyAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_property_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePropertyAuction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_property_auction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
